export default {
    s3: {
      REGION: "us-east-2",
      BUCKET: "aa-notes"
    },
    apiGateway: {
      REGION: "us-east-2",
      URL: "https://qyjjjdukjf.execute-api.us-east-2.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_oMGvbTyrs",
      APP_CLIENT_ID: "3f6gh9kjird321ia3s9ds0llt",
      IDENTITY_POOL_ID: "us-east-2:0cbce8b5-cfee-4eec-8168-3456bf976661"
    }, 
    MAX_ATTACHMENT_SIZE: 5000000
  };